










import { Component, Vue, Prop } from 'vue-property-decorator'
import QuestionVertical from '@/components/modules/drillsv3/molecules/QuestionVertical.vue'
import QuestionHorizontal from '@/components/modules/drillsv3/molecules/QuestionHorizontal.vue'

@Component({
  components: {
    QuestionVertical,
    QuestionHorizontal,
  },
})
export default class QuestionImage extends Vue {
  @Prop({ default: [] })
  dokkaiUrl?: string[]

  @Prop({ default: [] })
  mondaiUrl!: string[]

  @Prop({ default: [] })
  sijiUrls!: string[]

  @Prop()
  subjectCode?: string

  private show = false

  private get isVertical() {
    return this.subjectCode === 'ko'
  }

  private get sijiUrl() {
    return this.sijiUrls[0] || ''
  }

  public showImage(): void {
    this.show = true
    document.body.style.overflow = 'hidden'
  }

  public hideImage(): void {
    this.show = false
    document.body.style.overflow = 'auto'
  }
}
