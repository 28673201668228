



















import ScrollGuide from '@/components/modules/drills/atoms/ScrollGuide.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    ScrollGuide,
  },
})
export default class QuestionHorizontal extends Vue {
  @Prop({ default: [] })
  dokkaiUrl!: string[]

  @Prop({ default: [] })
  mondaiUrl!: string[]

  @Prop({ default: null })
  sijiUrl!: string | null

  private get hasDokkai(): boolean {
    return this.dokkaiUrl.length > 0
  }
}
