import { render, staticRenderFns } from "./QuestionVertical.vue?vue&type=template&id=bf0d7dee&scoped=true&lang=pug&"
import script from "./QuestionVertical.vue?vue&type=script&lang=ts&"
export * from "./QuestionVertical.vue?vue&type=script&lang=ts&"
import style0 from "./QuestionVertical.vue?vue&type=style&index=0&id=bf0d7dee&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf0d7dee",
  null
  
)

export default component.exports