
















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class QuestionVertical extends Vue {
  @Prop({ default: [] })
  dokkaiUrl!: string[]

  @Prop({ default: [] })
  mondaiUrl!: string[]

  @Prop({ default: null })
  sijiUrl!: string | null

  private get hasDokkai(): boolean {
    return this.dokkaiUrl?.length > 0
  }
}
