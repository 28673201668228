export interface Message {
  userMessage: string
  hintMessage: string | null
  createdAt?: string
  error?: boolean
}

export interface ResponseQuestions {
  histories: Message[]
  page: number
  perPage: number
  totalPage: number
}

export interface TimelineChat {
  createAt: string
  unitTitle: string
  pageNum: number
  sortNum: string
  curriculumSUnitId: number
  questionCode: string
  questionImage: {
    m: string[]
    d: string[]
    j: string
  }
  subjectCode: string
}

export interface ResponseTimelineChat {
  histories: TimelineChat[]
  page: number
  perPage: number
  totalPage: number
}

export enum ActionTimelineChat {
  CHAT = 'chat',
  SHOW_QUESTION = 'show-question',
}

export enum SubjectCode {
  MATH = 'su',
  ENGLISH = 'ei',
  LITERATURE = 'ko',
  SCIENCE = 'ri',
  SOCIETY = 'sh'
}