




























import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import { Component, Vue, Ref } from 'vue-property-decorator'
import TableBase from '@/components/atoms/TableBase1110.vue';
import SelectBase from '@/components/atoms/SelectBase.vue'
import QuestionImage from '@/components/modules/drillsv3/molecules/QuestionImage.vue'
import { SubjectCode } from '@/types/student'
import { DRILL_TYPE } from '@/store/modules/DrillsV3';
interface ChallengeReponse {
  id: number;
  questionCode: string;
  questionImage: {
    d?: string[];
    m?: string[];
    j?: string[];
  },
  curriculumSUnitName: string;
  historyType: string;
  studyMaterial: string;
  curriculumSUnitId: number;
  classModeId: number;
  classCategoryId: number;
  subject: string;
  studyType: string;
  subjectCode: string;
}

interface RequestParams { subject_code?: string; history_type?: string; page: number; per_page: number, totalPage: number }
@Component({
  components: {
    SidebarSwitcher,
    TableBase,
    SelectBase,
    QuestionImage
  },
})
export default class Challenge extends Vue {
  @Ref() questionImage!: QuestionImage
  private currentRow: any = {
    questionImage: {
      d: [],
      j: [],
      m: [],
    },
    subjectCode: this.$route.params.subjectCode,
  }
  private tableFields = [
    { key: 'subject', label: '教科' },
    { key: 'historyType', label: '分類' },
    { key: 'curriculumSUnitName', label: '項目' },
    { key: 'questionCodeLink', label: '問題表示' },
    { key: 'detail_bookmark', label: '問題' },
  ]
  private dataTable: ChallengeReponse[] = []
  private params: RequestParams = { history_type: '', page: 1, per_page: 10, subject_code: '', totalPage: 1 }
  private subjectOptions: { text: string; value: string }[] = [
    { text: '教科', value: '' },
    { text: '英語', value: SubjectCode.ENGLISH },
    { text: '数学', value: SubjectCode.MATH },
    { text: '国語', value: SubjectCode.LITERATURE },
    { text: '理科', value: SubjectCode.SCIENCE },
    { text: '社会', value: SubjectCode.SOCIETY },
  ]

  private historyTypeOptions: { text: string; value: string }[] = [
    { text: '分類', value: '' },
    { text: 'リトライ', value: 'challenge_retry' },
    { text: '類題', value: 'challenge_similar' },
  ]


  private handleShowQuestion(_number: number, index: number) {
    const data = this.dataTable[index] as ChallengeReponse
    this.currentRow = { ...this.currentRow, ...data }
    this.questionImage.showImage()
  }

  private handleFormatDataChallenge(data: ChallengeReponse[]) {
    return data.map((item: ChallengeReponse) => ({
      ...item,
      questionCodeLink: {
        name: item?.questionCode,
        onclick: this.handleShowQuestion,
      },
      detail_bookmark: [{
        name: '確認',
        onclick: () => this.handleRetryOrSimilar(item)
      }],
    }))
  }

  private async handleRetryOrSimilar(currentChallenge: ChallengeReponse) {
    try {
      Vue.prototype.$loading.start()
      const startRetry = await Vue.prototype.$http.httpWithToken.post(currentChallenge.studyType === 'similar' ? 'v3/similar_questions/drills/start' : '/v3/question_bookmarks/drills/start', {
        question_code: currentChallenge.questionCode,
        curriculum_s_unit_id: currentChallenge.curriculumSUnitId,
        study_type: currentChallenge.studyType,
        class_category_id: currentChallenge.classCategoryId,
        class_mode_id: currentChallenge.classModeId,
        study_material: 'challenge',
      })
      Vue.prototype.$loading.complete()
      const resultDrillId = startRetry?.data?.resultDrillId
      if (resultDrillId) {
        if (currentChallenge.subjectCode === 'su') {
          this.$router.push(`/student/v3/${currentChallenge.studyType === 'similar' ? 'drill-similar-math' : 'drill-one-math'}/${currentChallenge.questionCode}?resultDrillId=${resultDrillId}&page=${startRetry?.data.currentPage}&curriculumSUnitId=${currentChallenge.curriculumSUnitId}&drillType=${DRILL_TYPE.DRILL_CHALLENGE}`);
        } else this.$router.push(`/student/v3/${currentChallenge.studyType === 'similar' ? 'drill-similar' : 'drill-one'}/${currentChallenge.questionCode}?resultDrillId=${resultDrillId}&page=${startRetry?.data.currentPage}&curriculumSUnitId=${currentChallenge.curriculumSUnitId}&drillType=${DRILL_TYPE.DRILL_CHALLENGE}`);

      }
    } catch (error) {
      const status = (error as any).response?.data?.status
      if (status === 422 || status === 400) {
        alert('類題の生成に失敗しました。しばらくしてから再試行してください。')
        return;
      }
    }

  }

  private async handleGetListChallenge(request: RequestParams): Promise<{ challenges: ChallengeReponse[], page: number; perPage: number; totalPage: number }> {
    Vue.prototype.$loading.start()
    const result = await Vue.prototype.$http.httpWithToken.get(`/v3/history/question_challenges`, { params: request })
    Vue.prototype.$loading.complete()
    return { challenges: result?.data?.challenges || [], page: parseInt(result?.data?.page) || 1, perPage: parseInt(result?.data?.perPage) || 10, totalPage: result?.data?.totalPage || 1 }
  }

  private async handleLoadMore() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    if (scrollTop + windowHeight >= documentHeight) {
      if (this.params.page < this.params.totalPage) {
        const response = await this.handleGetListChallenge({ ...this.params, page: this.params.page + 1, per_page: this.params.per_page });
        const newData = this.handleFormatDataChallenge(response?.challenges || [])
        this.dataTable = [...this.dataTable, ...newData]
        this.params = { ...this.params, page: response?.page, per_page: response?.perPage, totalPage: response?.totalPage }
      }
    }
  }

  private async handleGetDataTable(request: RequestParams) {
    Vue.prototype.$loading.start()


    const newRequest = {
      ...request,
      ...(request?.subject_code?.length ? { subject_code: request.subject_code } : {}),
      ...(request?.history_type?.length ? { history_type: request.history_type } : {}),
    }
    const body = { ...this.params, ...newRequest }
    const response = await this.handleGetListChallenge(body);
    Vue.prototype.$loading.complete()
    this.dataTable = this.handleFormatDataChallenge(response.challenges)
    this.params = body
  }

  private async mounted() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth' // Optional: adds smooth scrolling effect
    });
    this.handleGetDataTable(this.params)
    window.addEventListener('scroll', this.handleLoadMore);
  }

  private beforeDestroy() {
    window.removeEventListener('scroll', this.handleLoadMore);
  }
}
