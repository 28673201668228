





import { Component, Vue, Prop } from 'vue-property-decorator'
import { Debounce } from 'vue-debounce-decorator'
import _ from 'lodash'

@Component
export default class ScrollGuide extends Vue {
  private isScroll = false
  private isEndOfScroll = false

  private top = 0
  private left = 0

  // タイマー処理による表示判定の回数
  private setUpCount = 0

  // 1秒毎にスクロールアイコン表示判定を行う(念の為、5回を上限とする)
  private timer = setInterval(() => {
    this.setUp()
    this.setUpCount += 1
    if (this.setUpCount >= 5) clearInterval(this.timer)
  }, 1000)

  // 同一画面でスクロール判定対象が複数ある場合、nameを指定して判定対象を指定する
  @Prop({ default: 'scrollable' })
  name!: string

  private get scrollable(): Element {
    return document.getElementsByClassName(this.name)[0]
  }

  get styles(): { [key: string]: string } {
    return {
      '--top': `${this.top}px`,
      '--left': `${this.left}px`,
    }
  }

  private mounted() {
    window.addEventListener('resize', this.handleResize)
    this.scrollable.addEventListener('scroll', this.handleScroll)
  }

  private beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
    this.scrollable.removeEventListener('scroll', this.handleScroll)

    clearInterval(this.timer)
  }

  private handleResize(): void {
    // resizeのたびに発火
    this.isScroll = false
    this.setUp()
  }

  @Debounce(300)
  private setUp() {
    if (!this.scrollable) {
      console.error('scrollable要素が設定されていません')
      return
    }

    this.top = this.scrollable.clientHeight - 70
    this.left = this.scrollable.clientWidth - 80
    this.isScroll = this.scrollable.scrollHeight > this.scrollable.clientHeight
  }

  private handleScroll = _.throttle(this.changeScrollState, 500)

  private changeScrollState() {
    if (!this.scrollable) {
      console.error('scrollable要素が設定されていません')
      return
    }
    const scrollHeight = this.scrollable.scrollHeight
    const scrollPosition = this.scrollable.clientHeight + this.scrollable.scrollTop

    this.isEndOfScroll = (scrollHeight - scrollPosition) / scrollHeight <= 0
  }
}
